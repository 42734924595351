import React, { useEffect, useMemo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import EventBus from 'eventing-bus';
import { get } from 'lodash';

import Properties from './HomeProperties.component';
import Project from './HomeProject.component';
import Specialist from './HomeSpecialist.component';
import BlogsList from '../../commons/blog/Blog.component';
import BannerSlides from '../../../layouts/commons/bannerSlides/BannerSlides';
import { getLanguage } from '../../../utils/params';

const HomeComponent = props => {
  const language = getLanguage(useLocation());
  const { t } = useTranslation();
  const propsId =
    get(props, 'layout.cityActive.defaultLocationId', null) || localStorage.getItem('LOCATION_CITY')
      ? localStorage.getItem('LOCATION_CITY')
      : '';

  useEffect(() => {
    if (propsId) {
      const params = { language, propertiesType: 0, pageIndex: 1, pageSize: 6, cityId: propsId };
      let propsIdForBlog;

      if(propsId == '3613eeb3-a88c-b394-ea44-5f1d4ff844c5') {
        propsIdForBlog = '2'
      } else if (propsId == '37a7b0d7-6fce-df8b-f54a-6604f7a89588') {
        propsIdForBlog = '3'
      } else if (propsId == '64aed160-8cfb-a33f-e9a3-5f1d4f652e6f') {
        propsIdForBlog = '1'
      } else {
        propsIdForBlog = '0'
      }
      const paramsForBlog = { language, propertiesType: 0, pageIndex: 1, pageSize: 6, city: propsIdForBlog };
      props.fetchLastestPropertiesRequest(params);
      props.fetchHotPropertiesRequest(params);
      props.fetchBlogsRequest(paramsForBlog);
      props.fetchProjectsRequest({ language, pageIndex: 1, pageSize: 8, cityId: propsId });
      props.fetchSpecialListRequest({ language, pageIndex: 1, pageSize: 6, cityId: propsId });
    }
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [propsId]);

  const { lastProperties, hotProperties, projects, specialList, blogs } = props.home;

  const lastestProperties = useMemo(() => {
    if (lastProperties.items && lastProperties.items.length) {
      return (
        <Properties
          section="RESIDENCES_LIKE"
          key="Latest Properties"
          headerTitle={t('home_page.lastest_properties')}
          class={'latest-properties'}
          data={lastProperties}
          metaKey="last"
        />
      );
    }

    // eslint-disable-next-line
  }, [lastProperties]);

  const hotsProperties = useMemo(() => {
    if (hotProperties.items && hotProperties.items.length) {
      return (
        <Properties
          section="RESIDENCES_LIKE"
          key="Hot Properties"
          headerTitle={t('home_page.hot_properties')}
          class={'host-properties'}
          data={hotProperties}
          metaKey="hot"
        />
      );
    }

    // eslint-disable-next-line
  }, [hotProperties]);

  const projectArea = useMemo(() => {
    if (projects && projects.length) return <Project data={projects} />;
    // eslint-disable-next-line
  }, [projects]);

  const specialListArea = useMemo(() => {
    if (specialList.items && specialList.items.length) return <Specialist data={specialList} />;
    // eslint-disable-next-line
  }, [specialList]);

  const blogsArea = useMemo(() => {
    if (blogs.items && blogs.items.length) return <BlogsList data={blogs} />;
    // eslint-disable-next-line
  }, [blogs]);

  return (
    <>
      <BannerSlides showResidenceSearch={true} checkResidenceAndOffice = {true}/>
      <div className="section-block background-gray">
        {lastestProperties}
        {hotsProperties}
      </div>
      {projectArea}
      {specialListArea}
      {blogsArea}
    </>
  );
};

export default withRouter(HomeComponent);
